.horizontal-swiper :global(.swiper) {
  margin: theme(margin.0);
}

.horizontal-swiper :global(.swiper-pagination) {
  position: static;
  min-height: 1.5rem;
}

.horizontal-swiper :global(.swiper-pagination-bullet) {
  width: 1.25rem;
  height: 0.3125rem;
  border-radius: theme(borderRadius.2xl);
  background-color: theme(colors[grayscale-300]);
  transition: all theme(transitionDuration.500);

  @media screen and (min-width: theme(screens[mobileUp])) {
    width: 1.75rem;
    height: 0.5rem;
  }
}

.horizontal-swiper :global(.swiper-pagination-bullet-active) {
  width: theme(width.6);
  background-color: theme(colors[primary-300]);

  @media screen and (min-width: theme(screens[mobileUp])) {
    width: theme(width.8);
  }
}
